import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'
import { white } from 'src/styles/colors'

export const Section = styled.div`
  .img-header {
    @media ${device.tablet} {
      width: 326px;
      float: right;
    }
    @media ${device.desktopLG} {
      width: 365px;
      float: right;
    }
    @media ${device.desktopXL} {
      width: 454px;
      float: right;
    }
  }
  > section {
    align-items: center;
    min-height: 100vh;

    &::before {
      background-image: linear-gradient(0deg, rgba(198, 71, 0, 0.8), rgba(198, 71, 0, 0.8));
    }

    @media ${device.tablet} {
      min-height: 768px;
    }

    >.container {
      padding-top: 60px;

      @media ${device.desktopLG} {
        padding-top: 0;
      }
    }
  }
`
export const FAQSection = styled.section`
  .search-input {
    background: ${white};
  }
`
