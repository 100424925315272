import { useStaticQuery, graphql } from 'gatsby'

const PageQuery = () => {
  const pageQuery = useStaticQuery(graphql`
    query {
      imgCRA: imageSharp(fluid: {originalName: { regex: "/cra-certificado-de-recebiveis-do-agronegocio/" }}) {
        fluid(maxWidth: 567, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  `)

  return pageQuery
}

export default PageQuery
